/*!
 * IUC site script: utility methods.
 */
window.IUCNORR.defineModule('util', function(core, fn, win, $) {
  'use strict';

  var $win = $(win),
    html = win.document.documentElement,
    $pageHero = $('.page-hero'),
    restRoot = null,
    /* Debounced event callbacks */
    debouncedCallbacks = {
      resize: [],
      scroll: [],
    },
    /* Cached dimension checks */
    dimension = {
      windowWidth: 0,
      windowHeight: 0,
      pageHeroHeight: 0,
    };

  /**
   * Set some element dimensions.
   *
   * Set on page load and resize, to prevent querying the DOM every time a value
   * is needed.
   */
  function setDimensions() {
    dimension.windowWidth = document.documentElement.clientWidth;
    dimension.windowHeight = $win.height();
    dimension.pageHeroHeight = $pageHero.height();
  }

  fn.getPageHeroHeight = function() {
    return dimension.pageHeroHeight;
  };

  /**
   * Get the current window/viewport width.
   *
   * @return {number}
   */
  fn.getWindowWidth = function() {
    return dimension.windowWidth;
  };

  /**
   * Get the current window/viewport height.
   *
   * @return {number}
   */
  fn.getWindowHeight = function() {
    return dimension.windowHeight;
  };

  /**
   * Get the current window/viewport top position.
   *
   * @return {number}
   */
  fn.getScrollTop = function() {
    return win.pageYOffset || html.scrollTop;
  };

  /**
   * Get the current window/viewport bottom position.
   *
   * @return {number}
   */
  fn.getScrollBottom = function() {
    return fn.getScrollTop() + fn.getWindowHeight();
  };

  /**
   * Log a message with the specified console method if the console is
   * available.
   *
   * @param {string} msg Message to log.
   * @param {string} method Console method to call.
   */
  function consoleMessage(msg, method) {
    if (window.console && window.console[method]) {
      window.console[method](msg);
    }
  }

  /**
   * Log a message to the console.
   *
   * @param {mixed} msg Message to log.
   */
  fn.log = function(msg) {
    consoleMessage(msg, 'log');
  };

  /**
   * Log a warning to the console.
   *
   * @param {mixed} msg Message to log.
   */
  fn.warn = function(msg) {
    consoleMessage(msg, 'warn');
  };

  /**
   * Check if a value exists in an array.
   *
   * Current array check won't work cross frames, but that's okay on this site.
   * http://perfectionkills.com/instanceof-considered-harmful-or-how-to-write-a-robust-isarray/
   *
   * @param {array} arr Array to check.
   * @param {mixed} val Calue to check for.
   * @return {bool} True if value is found, false otherwise.
   */
  fn.isInArray = function(arr, val) {
    if (arr instanceof Array) {
      for (var i = 0, len = arr.length; i < len; i++) {
        if (val === arr[i]) {
          return true;
        }
      }
    }

    return false;
  };

  /**
   * Get HTML for an SVG icon image.
   *
   * @param {string} iconName Icon name, sans 'icon-' prefix or file extension.
   * @return {string}
   */
  fn.getIconHtml = function(iconName) {
    return (
      '<span class="icon icon-' +
      iconName +
      '" role="presentation" aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg"><use xlink:href="#icon-' +
      iconName +
      '"></use></svg></span>'
    );
  };

  /**
   * Creates a debounced function that delays invoking `func` until after `wait`
   * milliseconds have elapsed since the last time the debounced function was
   * invoked.
   *
   * The debounced function comes with a `cancel` method to cancel delayed
   * `func` invocations and a `flush` method to immediately invoke them.
   * Provide an options object to indicate whether `func` should be invoked
   * on the leading and/or trailing edge of the `wait` timeout. The `func`
   * is invoked with the last arguments provided to the debounced function.
   * Subsequent calls to the debounced function return the result of the last
   * `func` invocation.
   *
   * **Note:** If `leading` and `trailing` options are `true`, `func` is invoked
   * on the trailing edge of the timeout only if the debounced function is
   * invoked more than once during the `wait` timeout.
   *
   * From Lodash, slightly tweaked.
   *
   * @param {function} func The function to debounce.
   * @param {number} [wait=0] The number of milliseconds to delay.
   * @param {object} [options={}] The options object.
   * @param {bool} [options.leading=false]
   *  Specify invoking on the leading edge of the timeout.
   * @param {number} [options.maxWait]
   *  The maximum time `func` is allowed to be delayed before it's invoked.
   * @param {bool} [options.trailing=true]
   *  Specify invoking on the trailing edge of the timeout.
   * @return {function} Returns the new debounced function.
   */
  fn.debounce = function(func, wait, options) {
    /* eslint no-undefined: "off" */

    var lastArgs,
      lastThis,
      maxWait,
      result,
      timerId,
      lastCallTime,
      lastInvokeTime = 0,
      leading = false,
      maxing = false,
      trailing = true;

    if (typeof func !== 'function') {
      throw new TypeError('Expected a function');
    }

    wait = parseInt(wait, 10) || 0;

    if ('object' === typeof options) {
      leading = !!options.leading;
      maxing = 'maxWait' in options;
      maxWait = maxing
        ? Math.max(parseInt(options.maxWait, 10) || 0, wait)
        : maxWait;
      trailing = 'trailing' in options ? !!options.trailing : trailing;
    }

    function invokeFunc(time) {
      var args = lastArgs,
        thisArg = lastThis;

      lastArgs = lastThis = undefined;
      lastInvokeTime = time;
      result = func.apply(thisArg, args);

      return result;
    }

    function leadingEdge(time) {
      // Reset any `maxWait` timer.
      lastInvokeTime = time;

      // Start the timer for the trailing edge.
      timerId = setTimeout(timerExpired, wait);

      // Invoke the leading edge.
      return leading ? invokeFunc(time) : result;
    }

    function remainingWait(time) {
      var timeSinceLastCall = time - lastCallTime,
        timeSinceLastInvoke = time - lastInvokeTime,
        remaining = wait - timeSinceLastCall;

      return maxing
        ? Math.min(remaining, maxWait - timeSinceLastInvoke)
        : remaining;
    }

    function shouldInvoke(time) {
      var timeSinceLastCall = time - lastCallTime,
        timeSinceLastInvoke = time - lastInvokeTime;

      // Either this is the first call, activity has stopped and we're at the
      // trailing edge, the system time has gone backwards and we're treating
      // it as the trailing edge, or we've hit the `maxWait` limit.
      return (
        lastCallTime === undefined ||
        timeSinceLastCall >= wait ||
        timeSinceLastCall < 0 ||
        (maxing && timeSinceLastInvoke >= maxWait)
      );
    }

    function timerExpired() {
      var time = Date.now();
      if (shouldInvoke(time)) {
        return trailingEdge(time);
      }
      // Restart the timer.
      timerId = setTimeout(timerExpired, remainingWait(time));
    }

    function trailingEdge(time) {
      timerId = undefined;

      // Only invoke if we have `lastArgs` which means `func` has been
      // debounced at least once.
      if (trailing && lastArgs) {
        return invokeFunc(time);
      }
      lastArgs = lastThis = undefined;

      return result;
    }

    function cancel() {
      if (timerId !== undefined) {
        clearTimeout(timerId);
      }
      lastInvokeTime = 0;
      lastArgs = lastCallTime = lastThis = timerId = undefined;
    }

    function flush() {
      return timerId === undefined ? result : trailingEdge(Date.now());
    }

    function debounced() {
      var time = Date.now(),
        isInvoking = shouldInvoke(time);

      lastArgs = arguments;
      lastThis = this;
      lastCallTime = time;

      if (isInvoking) {
        if (timerId === undefined) {
          return leadingEdge(lastCallTime);
        }
        if (maxing) {
          // Handle invocations in a tight loop.
          timerId = setTimeout(timerExpired, wait);

          return invokeFunc(lastCallTime);
        }
      }
      if (timerId === undefined) {
        timerId = setTimeout(timerExpired, wait);
      }

      return result;
    }

    debounced.cancel = cancel;
    debounced.flush = flush;

    return debounced;
  };

  /**
   * Creates a throttled function that only invokes `func` at most once per
   * every `wait` milliseconds.
   *
   * The throttled function comes with a `cancel` method to cancel delayed
   * `func` invocations and a `flush` method to immediately invoke them.
   * Provide an options object to indicate whether `func` should be invoked
   * on the leading and/or trailing edge of the `wait` timeout. The `func` is
   * invoked with the last arguments provided to the throttled function.
   * Subsequent calls to the throttled function return the result of the last
   * `func` invocation.
   *
   * **Note:** If `leading` and `trailing` options are `true`, `func` is
   * invoked on the trailing edge of the timeout only if the throttled function
   * is invoked more than once during the `wait` timeout.
   *
   * From Lodash, slightly tweaked.
   *
   * @param {function} func The function to throttle.
   * @param {number} [wait=0] The number of milliseconds to throttle invocations to.
   * @param {object} [options={}] The options object.
   * @param {bool} [options.leading=true] Specify invoking on the leading
   *    edge of the timeout.
   * @param {bool} [options.trailing=true] Specify invoking on the trailing
   *    edge of the timeout.
   * @return {function} Returns the new throttled function.
   */
  fn.throttle = function(func, wait, options) {
    var leading = true,
      trailing = true;

    if (typeof func !== 'function') {
      throw new TypeError('Expected a function');
    }

    if ('object' === typeof options) {
      leading = 'leading' in options ? !!options.leading : leading;
      trailing = 'trailing' in options ? !!options.trailing : trailing;
    }

    return fn.debounce(func, wait, {
      leading: leading,
      maxWait: wait,
      trailing: trailing,
    });
  };

  /**
   * Escape a string for HTML output.
   *
   * @param {string} str String to escape.
   * @return {string}
   */
  fn.escapeHtml = function(str) {
    var entityMap = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#39;',
      '/': '&#x2F;',
    };

    return str.replace(/[&<>"'/]/g, function(match) {
      return entityMap[match];
    });
  };

  /**
   * Check if fixed positioning can be used.
   *
   * Function from jQuery Mobile 1.4.5, which at this time of writing is
   * located in js/support.js in their GitHub repository.
   *
   * Uses user agent sniffing to return false for platforms that are known
   * to have problematic or no support. Sucks to not have proper feature
   * detection, but it's a very tricky feature to test, which would explain
   * why it's still not part of Modernizr at this time of writing.
   *
   * The Android check is changed from WebKit 533 (Android 2.3) to WebKit 534
   * (Android 3), since versions less than 3 requires page zooming to be
   * disabled for fixed to work.
   *
   * @return {bool}
   */
  fn.supportsPositionFixed = function() {
    var nav = navigator,
      ua = nav.userAgent,
      platform = nav.platform,
      // Rendering engine is WebKit, and capture major version
      wkmatch = ua.match(/AppleWebKit\/([0-9]+)/),
      wkversion = !!wkmatch && wkmatch[1],
      ffmatch = ua.match(/Fennec\/([0-9]+)/),
      ffversion = !!ffmatch && ffmatch[1],
      operammobilematch = ua.match(/Opera Mobi\/([0-9]+)/),
      omversion = !!operammobilematch && operammobilematch[1];

    if (
      // iOS less than 5: Platform is iPhone/iPad/iPod and WebKit version is
      // less than 534 (iOS 5)
      ((platform.indexOf('iPhone') > -1 ||
        platform.indexOf('iPad') > -1 ||
        platform.indexOf('iPod') > -1) &&
        wkversion &&
        wkversion < 534) ||
      // Opera Mini
      (win.operamini &&
        {}.toString.call(win.operamini) === '[object OperaMini]') ||
      (operammobilematch && omversion < 7458) ||
      // Android less than 3: Platform is Android and WebKit version is less
      // than 534 (Android 3)
      (ua.indexOf('Android') > -1 && wkversion && wkversion < 534) ||
      // Firefox Mobile before 6.0
      (ffversion && ffversion < 6) ||
      // WebOS less than 3
      ('palmGetResource' in win && wkversion && wkversion < 534) ||
      // MeeGo
      (ua.indexOf('MeeGo') > -1 && ua.indexOf('NokiaBrowser/8.5.0') > -1)
    ) {
      return false;
    }

    return true;
  };

  fn.getRESTEndpoint = function(endpoint) {
    var addedEndpoint = endpoint ? endpoint : '';

    if (!restRoot) {
      restRoot = $('link[rel="https://api.w.org/"]').attr('href');
    }

    return restRoot + addedEndpoint;
  };

  fn.template = function(tpl, data, doEscape) {
    var output = tpl,
      key,
      val,
      re;

    if ('undefined' === typeof doEscape) {
      doEscape = true;
    }

    for (key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        val = data[key];

        re = new RegExp('{{' + key + '}}', 'g');

        output = output.replace(re, val);
      }
    }

    return output;
  };

  /**
   * Add a debounced event callback.
   *
   * Since debounced resize and scroll events are registered in this core
   * module, adding extra callbacks to run at the same time is preferable
   * to binding multiple new ones in other modules.
   *
   * @param {function} func The callback to add.
   * @param {string} type Type of callback, 'scroll' or 'resize'.
   */
  function addDebouncedCallback(func, type) {
    if ('function' === typeof func) {
      debouncedCallbacks[type].push(func);
    }
  }

  /**
   * Add a debounced resize event callback.
   *
   * @param {function} func Callback to add.
   */
  fn.addResizeCallback = function(func) {
    addDebouncedCallback(func, 'resize');
  };

  /**
   * Add a debounced scroll event callback.
   *
   * @param {function} func Callback to add.
   */
  fn.addScrollCallback = function(func) {
    addDebouncedCallback(func, 'scroll');
  };

  /**
   * Debounced event callback. Run all registered callbacks.
   *
   * @param {object} e Event object.
   */
  function handleDebouncedEvent(e) {
    var callbacks = debouncedCallbacks[e.type],
      i = 0,
      len;

    if (callbacks && callbacks.length) {
      len = callbacks.length;

      for (i; i < len; i++) {
        callbacks[i]();
      }
    }
  }

  /**
   * Bind globally used events.
   */
  function bindEvents() {
    var type;

    for (type in debouncedCallbacks) {
      if (
        Object.prototype.hasOwnProperty.call(debouncedCallbacks, type) &&
        debouncedCallbacks[type]
      ) {
        $win.on(type, core.util.debounce(handleDebouncedEvent, 250));
      }
    }

    // Set dimensions again when images are loaded
    core.lazyImage.addLoadedCallback(setDimensions);

    // Set dimensions again when the window is resized
    fn.addResizeCallback(setDimensions);
  }

  /**
   * Initialize internal stuff.
   */
  fn.init = function() {
    setDimensions();
    bindEvents();
  };
});
