/*!
 * IucNorr site script: Magnific Popup lightbox handling.
 *
 * Lucid Gallery Lightbox only does images, this inits lightboxes with arbitrary
 * HTML content. Use the trigger class on the element that opens the lightbox
 * and set the `data-mfp-src` attribute on it with a selector to the content
 * that should be opened. The content should have the `mfp-hide` class.
 */
window.IUCNORR.defineModule('lightbox', function(core, fn, win, $) {
  'use strict';

  var $body = $('body');

  // Container for all triggers that should belong to the same 'gallery'
  var containerSelector = '.js-inline-lightbox-container';

  // Main trigger element. Should have the `data-mfp-src` attribute.
  var triggerSelector = '.js-lightbox-trigger';

  // Element that will trigger the main trigger (whoa!). Just using multiple
  // triggers will cause multiple instances of the same lightbox content when
  // paging with the arrows.
  var extraTriggerSelector = '.js-lightbox-extra-trigger';

  // Wrap when using multiple triggers
  var triggersWrapSelector = '.js-lightbox-triggers-wrap';

  var options = {
    type: 'inline',
    delegate: triggerSelector,
    // Also open on middle click since there isn't a full page being linked to
    midClick: true,
    gallery: {
      enabled: true,
    },
  };

  /**
   * Find and click the main trigger when clicking on an extra one.
   */
  function onExtraTriggerClick() {
    $(this)
      .closest(triggersWrapSelector)
      .find(triggerSelector)
      .trigger('click');
  }

  /**
   * Initialization.
   */
  fn.init = function() {
    if (!$.fn.magnificPopup) {
      return;
    }

    // Add close for animation purposes if transitions are available
    if (window.Modernizr.csstransitions) {
      options.removalDelay = 250;
    }

    $(containerSelector).magnificPopup(options);

    $body.on('click', extraTriggerSelector, onExtraTriggerClick);
  };
});
