/*!
 * IUC site script: navigation menus.
 *
 * Implements a version of so-called priority navigation, where items in a
 * horizontal nav bar are hidden and cloned into a dropdown list that is
 * toggled with a button if they wrap below the first line.
 *
 * Also makes the main nav a tree view on smaller screens.
 */
window.IUCNORR.defineModule('navigation', function(core, fn, win, $) {
  'use strict';

  var conf = {
    // Class name for the list that should have the overflow funcitonality added
    navListClass: 'js-nav-overflow',

    // Class name for the list item that's appended to the original menu
    overflowWrapClass: 'nav-overflow-wrap',

    // Class name for the list containing the overflow items
    overflowListClass: 'nav-overflow-list',

    // Class name that is toggled with the overflow toggle button
    toggleClass: 'nav-overflow-wrap--open',

    // Class name for the overflow toggle button
    toggleButtonClass: 'nav-overflow-toggle',
  };

  var mainNav = {
    toggleSelector: '#main-nav-toggle',
    $toggle: null,
    mainNavSelector: '#main-nav',
    parentItemsSelector: '.parent-item > a',
  };

  // Attributes set on the added DOM nodes. Used with a prefix or suffix
  // depending on if the list has an ID.
  // With ID: <listID>-<attr>, e.g. 'main-nav-overflow-toggle'.
  // Without: <attr>-<counter>, e.g. 'overflow-toggle-1'.
  var partialAttr = {
    overflowToggleId: 'overflow-toggle',
    overflowListId: 'overflow',
  };
  var instances = [];
  var instanceCount = 0;

  /**
   * Overflow list constructor.
   *
   * @param {object} $list jQuery object of the list to handle.
   * @return {Nav} this
   */
  var Nav = function($list) {
    this.$list = $list;
    this.init();

    return this;
  };

  /**
   * Check if the passed list object actually exists.
   *
   * @return {bool}
   */
  Nav.prototype.hasNav = function() {
    return !!this.$list.length;
  };

  /**
   * Cache DOM element references and set initial data.
   *
   * @return {Nav} this
   */
  Nav.prototype._initData = function() {
    if (!this.hasNav()) {
      return this;
    }

    this.$overflowWrap = this.$list.find('.' + conf.overflowWrapClass);
    this.$navItems = this.$list.children('li').filter(function(i, el) {
      return el.className.indexOf(conf.overflowWrapClass) === -1;
    });
    this.$overflowList = this.$overflowWrap.children('ul');
    this.$overflowItems = null;

    this.data = {
      itemHeight: 0,
      firstItemTop: 0,
    };

    return this;
  };

  /**
   * Get attributes for the inserted DOM nodes.
   *
   * @return {object} Based on partialAttr.
   */
  Nav.prototype._getAttr = function() {
    var listId = this.$list[0].id,
      prefix = '',
      suffix = '',
      attr = $.extend({}, partialAttr);

    if (listId) {
      prefix = listId + '-';
    } else {
      suffix = '-' + instanceCount;
    }

    Object.keys(attr).map(function(key) {
      attr[key] = prefix + attr[key] + suffix;
    });

    return attr;
  };

  /**
   * Add the overflow list toggle button.
   *
   * @return {Nav} this
   */
  Nav.prototype._addToggleButton = function() {
    var attr = this._getAttr(),
      $overflowWrap,
      $button,
      $list;

    $button = $(
      '<button type="button"><span class="text">' +
        core.themeData.text.navOverflowToggle +
        '</span>' +
        core.util.getIconHtml('chevron-down') +
        '</button>'
    ).attr({
      'id': attr.overflowToggleId,
      'class': 'js-toggle ' + conf.toggleButtonClass,
      'aria-controls': attr.overflowListId,
      'data-toggle': conf.toggleClass,
      'data-toggle-on': 'parent',
      'data-close-outside': 'true',
    });

    $list = $('<ul></ul>').attr({
      id: attr.overflowListId,
      class: conf.overflowListClass,
    });

    $overflowWrap = $('<li class="hidden ' + conf.overflowWrapClass + '"></li>')
      .append($button)
      .append($list);

    this.$list.append($overflowWrap);

    return this;
  };

  /**
   * Set cache data that may change during the page's lifetime (like on resize).
   *
   * @return {Nav} this
   */
  Nav.prototype._updateData = function() {
    // Manually get the first visible item instead of using the :visible filter
    // on all nav items, since that can be expensive
    var firstVisible = null;
    this.$navItems.each(function() {
      if (!firstVisible && $(this).is(':visible')) {
        firstVisible = this;
      }
    });

    if (!firstVisible) {
      core.util.warn('No first visible nav item for nav overflow');
      core.util.log(this.$navItems);

      return this;
    }

    this.data.firstItemTop = $(firstVisible).position().top;

    return this;
  };

  /**
   * Check if the main nav elements have wrapped to multiple rows.
   *
   * @return {bool}
   */
  Nav.prototype.isMenuWrapped = function() {
    // Due to potential rendering weirdness where something is a pixel smaller
    // than it should be etc., let's check if the nav height minus half an
    // item's height is still taller than a single nav item. If so there should
    // definitely be at least two rows of items.
    return (
      this.$list.height() - this.data.itemHeight / 2 > this.data.itemHeight
    );
  };

  /**
   * Hide the overflow menu toggle button.
   *
   * @return {Nav} this
   */
  Nav.prototype.hideOverflowControls = function() {
    this.$overflowWrap.addClass('hidden');

    return this;
  };

  /**
   * Show the overflow menu toggle button.
   * @return {Nav} this
   */
  Nav.prototype.showOverflowControls = function() {
    this.$overflowWrap.removeClass('hidden');

    return this;
  };

  /**
   * Check if a nav item is wrapped below the top row.
   *
   * @param {object} $item jQuery object of the item to check.
   * @return {bool}
   */
  Nav.prototype.isItemWrapped = function($item) {
    return $item.position().top > this.data.firstItemTop;
  };

  /**
   * Get all nav elements that are wrapped below the top row.
   *
   * @return {object} jQuery collection, may be empty.
   */
  Nav.prototype.getWrappedItems = function() {
    var $wrappedItems = $(),
      isFirstWrapped = true,
      _this = this;

    _this.$navItems.each(function(index) {
      var item = this,
        $item = $(item);

      if (_this.isItemWrapped($item)) {
        $wrappedItems = $wrappedItems.add($item);

        // When hitting a wrapped item the first time, also include its nearest
        // previous sibling to make extra room for the toggle button that's
        // about to be visible; i.e. always include wrapped items plus one.
        if (isFirstWrapped) {
          $wrappedItems = $wrappedItems.add(_this.$navItems.eq(index - 1));
          isFirstWrapped = false;
        }
      }
    });

    return $wrappedItems;
  };

  /**
   * "Move" wrapped nav items by cloning them to the toggled list and hiding
   * the original ones.
   *
   * @param {object} $items jQuery collection of elements to "move".
   * @return {Nav} this
   */
  Nav.prototype._moveWrappedItems = function($items) {
    this.$overflowList.html($items.clone());
    $items.addClass('hidden');
    this.$overflowItems = $items;

    return this;
  };

  /**
   * Reset the main nav to default state.
   *
   * @return {Nav} this
   */
  Nav.prototype.resetNav = function() {
    this.$overflowList.empty();
    this.hideOverflowControls();

    if (this.$overflowItems) {
      this.$overflowItems.removeClass('hidden');
      this.$overflowItems = null;
    }

    return this;
  };

  /**
   * Resize callback; check the nav menu state.
   */
  Nav.prototype.check = function() {
    var $items;

    this.resetNav();
    this._updateData();

    if (!this.isMenuWrapped()) {
      return;
    }

    $items = this.getWrappedItems();

    if ($items.length) {
      this._moveWrappedItems($items);
      this.showOverflowControls();
    }
  };

  /**
   * Bind the events.
   *
   * @return {Nav} this
   */
  Nav.prototype._bindEvents = function() {
    core.util.addResizeCallback(this.check.bind(this));

    return this;
  };

  /**
   * Initialize a nav overflow list.
   */
  Nav.prototype.init = function() {
    instanceCount++;

    if (!this.hasNav()) {
      return;
    }

    this._addToggleButton()
      ._initData()
      ._bindEvents();

    this.check();
  };

  function isMainNavToggled() {
    return mainNav.$toggle.css('display') !== 'none';
  }

  function onMainNavParentClick(e) {
    if (!isMainNavToggled()) {
      return;
    }

    e.preventDefault();
    $(this)
      .parent()
      .toggleClass('parent-item--open');
  }

  /**
   * Initialize all lists.
   */
  fn.init = function() {
    $('.' + conf.navListClass).each(function() {
      var $list = $(this),
        nav = new Nav($list);

      instances.push(nav);
      $list.data('overflowNav', nav);
    });

    mainNav.$toggle = $(mainNav.toggleSelector);
    $(mainNav.mainNavSelector).on(
      'click',
      mainNav.parentItemsSelector,
      onMainNavParentClick
    );
  };
});
